// npm libraries
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { Modals } from '../repos/Modals'

import store from '../store/my-profile'
import { mapGetters, mapState } from 'vuex'
import { EventTracking } from '../plugins/EventTracking'
import TopnavUtil from '../mixins/TopnavUtil'
import PushListener from '../mixins/PushListener'
import WithGrooveHq from '../mixins/WithGrooveHq'
import WithPageViewTracking from '../mixins/WithPageViewTracking'

Vue.use(EventTracking, store)

//bootstrap components
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.prototype.$modals = Modals

window.app = new Vue({
    store,
    el: '#app',
    components: {
        LogoLoader: () => import('../components/logos/LogoLoader.vue'),
        MaintenanceAlert: () => import('../components/MaintenanceAlert.vue'),
        Canny: () => import('../components/Canny.vue'),
        MainMenu: () => import('../widgets/main-menu.vue'),
        Login: () => import('../components/Login.vue'),
        MwmModal: () => import('../widgets/modal.vue'),
    },
    mixins: [TopnavUtil, PushListener, WithGrooveHq, WithPageViewTracking],
    computed: {
        ...mapGetters({ isLoggedIn: 'user/isLoggedIn' }),
        ...mapState(['user']),
    },
    beforeCreate() {
        this.$store.dispatch('user/initialize')
    },
})
